import styled from 'styled-components'

export const BenefitsContainer = styled.div`
  ${(p) => {
    let paddingStyles = ''
    if (p.topPadding === 'Large') paddingStyles += 'padding-top: 96px;'
    else if (p.topPadding === 'Small') paddingStyles += 'padding-top: 64px;'
    if (p.bottomPadding === 'Large') paddingStyles += 'padding-bottom: 96px;'
    else if (p.bottomPadding === 'Small')
      paddingStyles += 'padding-bottom: 64px;'

    let alignmentStyles = ''
    if (p.layout === 'Text to Right') {
      alignmentStyles += `
        .Benefits__Item-Container {
          display: flex;
        }
        .Benefits__Text-Container {
          padding-left: 24px;
        }
        h6 {
          margin-bottom: 16px;
        }
      `
    }
    let themeStyles = ''

    themeStyles += `
        p, h6, li {
          color: rgb(0, 0, 0);
        }
      `

    let columnsStyles = `
      ${p.theme.breakpoints.down('sm')} {
        .Benefits__Inner-Container {
          width: calc(100% + 16px);
          margin: 0 -8px;
        }

        .Benefits__Item-Container {
          padding: 0 8px;
        }
      }
    `

    return `
      overflow: hidden;
      ${paddingStyles}
      ${alignmentStyles}
      ${themeStyles}
      ${columnsStyles}

      .Benefits__Item-Container {
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 48px;
        ${p.theme.breakpoints.down('sm')} {
          margin-top: 48px;
        }
        img {
          height: 64px;
          width: 64px;
          object-fit: contain;
        }
      }
    `
  }}

  .Benefits__Image-Container {
    margin-top: 93px;
    position: relative;
    z-index: 3;
    ${(p) => p.theme.breakpoints.down('sm')} {
      margin-top: 0px;
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
      margin-top: 0px;
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 80px;
      height: 4px;
      background: #fa4616;
    }
  }

  .Benefits__Inner-Container-Icons {
    max-width: 795px;
    width: 100%;
    margin-top: 77px;
    margin-left: 2px;
    ${(p) => p.theme.breakpoints.down('sm')} {
      max-width: 100%;
      margin-top: -8px;
      margin-left: -8px;
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
      max-width: 328px;
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
    }
  }

  .Benefits__Inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    ${(p) => p.theme.breakpoints.down('sm')} {
      flex-direction: column;
      align-items: flex-start;
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
      width: 100%;
      max-width: 328px;
    }

    .Benefits__Image-Graphic-Outer {
      position: relative;
      align-self: flex-start;
      padding-right: 108px;
      box-sizing: border-box;
      ${(p) => p.theme.breakpoints.down('md')} {
        padding-right: 28px;
      }
      ${(p) => p.theme.breakpoints.down('sm')} {
        padding-right: 0;
        padding-top: 48px;
      }
      &:before {
        content: '';
        background-image: radial-gradient(#696969 1px, transparent 0);
        position: absolute;
        width: 486px;
        height: 320px;
        z-index: 0;
        top: 0;
        right: 0;
        background-size: 24px 24px;

        ${(p) => p.theme.breakpoints.down('sm')} {
          width: 529px;
          height: 160px;
          left: 0;
          top: -48px;
        }
        ${(p) => p.theme.breakpoints.down('xs')} {
          width: 328px;
          height: 160px;
        }
      }
    }

    .Benefits__Inner-Text {
      max-width: 691px;
      width: 100%;
      margin-left: auto;
      ${(p) => p.theme.breakpoints.down('md')} {
        max-width: 550px;
      }
      ${(p) => p.theme.breakpoints.down('sm')} {
        max-width: 454px;
      }
      ${(p) => p.theme.breakpoints.down('xs')} {
        max-width: 328px;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  .Benefits__Text-Container {
    .Benefits__Benefit-Header {
      margin-bottom: 8px;
      color: #000b23;
      ${(p) => p.theme.breakpoints.up('md')} {
        letter-spacing: -1px;
      }
    }
    .Benefits__Benefit-Body {
      margin: 0;
      p {
        color: #273139;
        ${(p) => p.theme.breakpoints.down('sm')} {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }

  .Benefits__Image-Path {
    z-index: 2;
    position: absolute;
    min-width: 767px;
    width: 767px;
    height: 364px;
    top: 100px;
    right: 0;
    ${(p) => p.theme.breakpoints.down('sm')} {
      min-width: 383.5px;
      width: 383.5px;
      height: 192px;
      left: -32px;
      top: 30px;
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
      min-width: 327px;
      width: 327px;
      height: 164px;
      left: 0px;
      top: 0;
    }
  }

  .Benefits__Image {
    margin-bottom: 32px;
    width: 272px;
    height: 233px;

    ${(p) => p.theme.breakpoints.down('sm')} {
      width: 166px;
      height: 142px;
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
      width: 142px;
      height: 122px;
    }
  }

  .Benefits__Image,
  .Benefits__Image-Typography {
    z-index: 3;
  }

  .Benefits__Image-Typography {
    position: relative;
    max-width: 290px;
    margin-top: 28px;
    color: #000b23;
    ${(p) => p.theme.breakpoints.down('md')} {
      max-width: 255px;
    }
    ${(p) => p.theme.breakpoints.down('sm')} {
      max-width: 100%;
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
      margin-top: 24px;
    }
  }
`
