import styled from 'styled-components'
import Arrow from '../../../../static/steam-img/arrows/16x16_Arrow_Up.svg'

export const CollapsibleQuadrantContainer = styled.div`
  background: ${(p) => p.theme.webMktPalette.background.gray};
  padding-bottom: 48px;

  h1 {
    margin-top: 0;
    margin-bottom: 32px;
    padding: 0 16px;
    text-align: center;

    ${(p) => p.theme.breakpoints.down('sm')} {
      margin-bottom: 24px;
    }
  }

  .CollapsibleQuadrant__Outer-Container {
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    ${(p) => p.theme.breakpoints.down('md')} {
      max-width: 1090px;
    }
  }
  .CollapsibleQuadrant__Inner {
    position: relative;
    padding: 42px 16px 0;

    &.Chart-Active {
      @media screen and (max-width: 840px) {
        .CollapsibleQuadrant__Column-One .mobileWrapper img:nth-of-type(2),
        .CollapsibleQuadrant__Column-Two .mobileWrapper img:nth-of-type(2) {
          display: block;
        }
      }
      @media screen and (min-width: 841px) {
        .CollapsibleQuadrant__Column-One .desktopWrapper img:nth-of-type(2),
        .CollapsibleQuadrant__Column-Two .desktopWrapper img:nth-of-type(2) {
          display: block;
        }
      }

      .CollapsibleQuadrant__Button:before {
        transform: translateY(-50%);
      }
    }

    &:before {
      content: '';
      position: absolute;
      height: 286px;
      width: calc(100% + 6px);
      margin: 0 -3px;
      top: 0;
      left: 0;
      background-size: 24px 24px;
      background-image: radial-gradient(#4a4a4a 1px, transparent 0);
    }
  }

  .CollapsibleQuadrant__Content {
    background: ${(p) => p.theme.webMktPalette.background.white};
    max-width: 992px;
    margin: 0 auto;
    position: relative;
    padding-bottom: 64px;

    ${(p) => p.theme.breakpoints.down('md')} {
      max-width: 960px;
    }

    @media screen and (max-width: 840px) {
      padding-top: 24px;
    }
  }

  .CollapsibleQuadrant__Subheadline {
    text-align: center;
    padding: 32px 0;
  }

  .CollapsibleQuadrant__Subheadline + .CollapsibleQuadrant__Columns {
    padding-top: 0;
  }

  .CollapsibleQuadrant__Columns {
    display: flex;
    justify-content: center;
    padding-top: 96px;
    @media screen and (max-width: 840px) {
      flex-wrap: wrap;
      flex-direction: column;
      padding-top: 24px;
    }
  }

  .CollapsibleQuadrant__Column-One,
  .CollapsibleQuadrant__Column-Two {
    margin: 0 11px;

    .mobileWrapper {
      display: none;
      img {
        margin: 0 auto;
        &:first-of-type {
          margin-bottom: 15px;
        }
      }
    }

    > div img:nth-of-type(2) {
      display: none;
    }

    @media screen and (max-width: 840px) {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      .desktopWrapper {
        display: none;
      }
      .mobileWrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 35px;
      }
    }
  }

  .CollapsibleQuadrant__Column-One .desktopWrapper img:nth-of-type(1) {
    position: relative;
    right: 20px;
    margin-bottom: 31px;
  }

  .CollapsibleQuadrant__Column-Two .desktopWrapper img:nth-of-type(1) {
    position: relative;
    left: 20px;
    margin-bottom: 31px;
  }

  .CollapsibleQuadrant__Column-One .desktopWrapper img:nth-of-type(2),
  .CollapsibleQuadrant__Column-two .desktopWrapper img:nth-of-type(2) {
    margin-bottom: 31px;
  }

  .CollapsibleQuadrant__Column-One .desktopWrapper img:nth-of-type(2) {
    margin-left: auto;
  }

  .CollapsibleQuadrant__Button {
    display: block;
    line-height: 40px;
    margin: 0 auto;
    padding: 0 45px 0 20px;
    border: 2px solid black;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      background-image: url(${Arrow});
      background-size: 16px 16px;
      width: 16px;
      height: 16px;
      right: 20px;
      top: 50%;
      transform: translateY(-50%) rotate(180deg);
    }
  }
`
